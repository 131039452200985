<template>
  <v-main class="primary">
    <Appbar />
    <v-card :width="cardwidth" class="fill-height mx-auto ma-5">
      <v-form v-model="formulario" class="pt-5">
        <v-img
          contain
          :aspect-ratio="30 / 9"
          class="mx-auto my-3"
          :src="require('@/assets/pqrs.jpg')"
          width="60%"
        />
        <center>
          <h2 class="primary white--text" dark>
            SECRETARIA PLANEACIÓN MUNICIPAL FORMULARIO ÚNICO TRÁMITES Y
            RESPUESTAS
          </h2>
          <span
            >Para la Secretaría de Planeación Municipal es muy importante que
            todo petición, Queja, Reclamo, Sugerencia y Denuncia, se debe dar el
            tràmite correspondiente dentro de los tèrminos.</span
          >
        </center>
        <v-divider />
        <v-row class="ma-3" dense>
          <!-- <v-col cols="12">
            <v-text-field
              v-model="datos.canalIngreso"
              label="Canal de ingreso"
              dense
              outlined
              maxlength="300"
              counter="300"
            />
          </v-col> -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="datos.correo"
              label="1. Correo Electrónico de Solicitud"
              :rules="[rules.email]"
              dense
              type="email"
              outlined
              maxlength="100"
              counter="100"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="datos.radicado"
              label="2. Oficio Físico - No. Radicado:"
              dense
              outlined
              maxlength="100"
              counter="100"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="datos.codigoPqrd"
              label="3. Plataforma PQRD (Contáctenos) - Código:"
              dense
              outlined
              maxlength="100"
              counter="100"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="datos.codigoSolicitud"
              label="4. Solicitud Verbal (Formato solicitud información) - No Consecutivo:"
              dense
              outlined
              maxlength="100"
              counter="100"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="datos.unidadCorrespondencia"
              label="5. Radicado Unidad de Correspondencia"
              dense
              :rules="[rules.required]"
              outlined
              maxlength="100"
              counter="100"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="datos.fechaRecibido"
              label="6. Fecha de recibido la solicitud"
              :rules="[rules.required]"
              dense
              type="date"
              outlined
              maxlength="100"
              counter="100"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="datos.tipoSolicitud"
              label="7. Tipo Solicitud:"
              :rules="[rules.required]"
              dense
              :items="itemsTipoSolicitud"
              outlined
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-if="datos.tipoSolicitud === 'Otro'"
              v-model="datos.otroTipoSolicitud"
              label="Otro, ¿Cual?:"
              :rules="[rules.required]"
              dense
              outlined
              maxlength="100"
              counter="100"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="datos.tipoAsunto"
              label="8. Tipo Asunto:"
              dense
              :items="itemsTipoAsunto"
              outlined
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="datos.asunto"
              label="9. ASUNTO (Descripción Breve del documento):"
              :rules="[rules.required]"
              dense
              outlined
              maxlength="100"
              counter="100"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="datos.predial"
              label="10. No. Predial (min 15 y max 30 dígitos):"
              :rules="[rules.counter30]"
              hint="Solo aplica a las solicitudes que cuentan con número predial y su longitud debe ser entre 15 y 30 caracteres sin espacios( - ) y sin los cinco primeros números (52001)."
              dense
              maxlength="30"
              outlined
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="datos.nombreSolicitante"
              label="11. Nombre del Solicitante:"
              dense
              outlined
              maxlength="100"
              counter="100"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="datos.entidadDependencia"
              label="12. Entidad o Dependencia:"
              dense
              outlined
              maxlength="100"
              counter="100"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
                v-model="datos.documentoSolicitud" 
                label="13. Documento Solicitud / Oficio (URL Drive)"
                :rules="[rules.required]"
                dense
                outlined
              />
            <!-- <v-file-input
              v-model="datos.documentoSolicitud"
              chips
              accept=".pdf"
              placeholder="Buscar PDF"
              label="13. Documento Solicitud / Oficio :(Formato PDF): (PDF Max 5Mb)"
              show-size
              counter
              hint="Se debe adjuntar la solicitud en formato PDF (Max 5MB)."
              outlined
              dense
              :rules="[rules.tamañoarchivo]"
            ></v-file-input> -->
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="datos.correoNotificacion"
              label="14. Correo de Notificación:"
              :rules="[rules.email]"
              hint="Es muy importante que se registre de manera correta el correo electrónico del solicitante para enviar la respuesta."
              dense
              outlined
              maxlength="100"
              counter="100"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="datos.telefono"
              label="15. Teléfono/Celular  Contacto:"
              dense
              outlined
              maxlength="20"
            />
          </v-col>
          <v-col cols="6" >
            <v-autocomplete
              v-model="datos.prioridad"
              label="16. Prioridad:"
              :rules="[rules.required]"
              :items="itemsPrioridad"
              dense
              outlined
            />
            <!-- hint="Es importante tener encuenta que la opción SI, soló se seleccionará cuando el Derecho de Petición requiera de Concepto Jurídico." -->
          </v-col>
          <v-col cols="6" v-if="datos.prioridad === 'Jurídica'">
            <v-autocomplete
              v-model="datos.prioridadJuridica"
              label="17. Tipo de prioridad juridica:"
              :rules="[rules.required]"
              :items="itemsPrioridadJuridica"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12">
            <center>
              <h4>AUTORIZACIÓN TRATAMIENTO DE DATOS PERSONALES</h4>
            </center>
            <p style="text-align: justify; font-size: 10px">
              Mediante el registro y envío de sus datos personales en el
              presente formulario en línea, usted autoriza a la Alcaldía de
              Pasto, Secretaría de Planeación, para la recolección,
              almacenamiento y uso de los mismos con la finalidad de adelantar
              el procedimiento de Registro de Derechos de petición, quejas,
              reclamos, denuncias y recibir información adicional que se pueda
              general del trámite. Como titular de la información tiene derecho
              a conocer, actualizar y rectificar sus datos personales, solicitar
              prueba de la autorización otorgada para su tratamiento, ser
              informado sobre el uso que se ha dado a los mismos, presentar
              quejas ante la Alcaldía de Pasto por infracción de ley al correo
              electrónico contactenos@pasto.gov.co, revocar la autorización y/o
              solicitar la supresión de sus datos en los casos en que sea
              procedente y acceder en formar gratuita a los mismos, dando
              cumplimiento a lo señalado en la Ley 1581 del 2012. Recuerde que
              puede consultar la política de protección de datos personales de
              nuestra entidad en nuestro sitio web www.pasto.gov.co
            </p>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-row class="ma-1" dense>
            <v-space></v-space>
            <v-col cols="12" align="">
              <v-btn
                :disabled="!formulario"
                color="success"
                @click="saveFormulario"
                width="100%"
                :loading = loadingSend
              >
                <!-- <v-btn  color="success" @click="saveFormulario" width="100%"> -->
                Enviar
              </v-btn>
            </v-col>
            <v-space></v-space>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-dialog
      v-model="dialogAyudaAdjunto"
      v-bind="$attrs"
      max-width="500px"
      permanent
    >
      <v-card>
        <v-toolbar class="info" dark dense
          ><v-icon left>mdi-help-box</v-icon>
          Ayuda
        </v-toolbar>
        <v-row class="ma-5">
          <v-col cols="12"
            ><center>
              {{ mensajeDeAyuda }}
            </center>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-main>
</template>
<script>
import Appbar from "../components/BarSesion";
import axios from "axios";

export default {
  data() {
    return {
      // $conexionGlobal: 'http://localhost/planeacion/backend/',
      // $conexionGlobal: 'http://192.168.2.144/planeacion/backend/',
      dialogAyudaAdjunto: false,
      mensajeDeAyuda: "",
      loadingSend: false, 
      itemsTipoSolicitud: [
        "Derecho de Petición",
        "Queja",
        "Reclamo",
        "Denuncia",
        "Sugerencia",
        "Tutela",
        "Recurso de Apelación",
        "Acción Popular",
        "Solicitud Trámites Sede Electrónica",
        "Otro",
      ],
      itemsTipoAsunto: [
        "Derecho de Petición",
        "Concepto norma Urbanística",
        "Concepto Uso de Suelo",
        "Concepto Uso como Establecido",
        "Concepto Pefil Víal",
        "Certificado Estratificación",
        "Certificado Nomenclatura",
        "Certificado Riesgos y Restricciones",
        "Certificado Edificación con Tratamiento de Conservación",
        "Liquitación Impuesto Delinación Urbana",
        "Información Proyectos/Programas Subsecretaría",
        "Personería Jurídica en Propiedad Horizontal",
        "Certificación de personería jurídica",
        "Concepto norma Urbanística - Concepto Perfil Vial",
        "Certificado Estratificación - Certificado Nomenclatura",
        "Recurso de Apelación",
        "Tutela",
        "Licencia Intervención y Ocupación de Espacio Público",
        "Acción Popular",
        "Opción 20",
      ],
      itemsPrioridadJuridica: [
        "Tutelas - acciones populares IAS",
        "Licencias de Intervención",
        "Propiedad Horizontal"
      ],
      itemsPrioridad: [
        "Jurídica",
        "Técnica",
        "Estratificación",
        "Asistencial",
        "Supervisión, Consultoría y Obras",
        "Planes parciales y proyectos PEMP"
      ],
      itemsResponsable: [
        "Alejandra Guerrero",
        "Libia Isabel León",
        "Nhora Salazar Rosero",
        "Jose Luis Solarte Ortega",
        "Daniela Urbano Delgado",
      ],
      dialogAyuda: false,
      formulario: true,
      datos: {
        correo: "",
        radicado: "",
        codigoPqrd: "",
        codigoSolicitud: "",
        unidadCorrespondencia: "",
        fechaRecibido: "",
        tipoSolicitud: "",
        otroTipoSolicitud: "",
        tipoAsunto: "",
        asunto: "",
        predial: "",
        nombreSolicitante: "",
        entidadDependencia: "",
        documentoSolicitud: "",
        correoNotificacion: "",
        telefono: "",
        prioridad: "",
        usuario: "",
        prioridadJuridica: 'Tutelas - acciones populares IAS'
      },
      ocultar: true,
      rules: {
        required: (value) => !!value || "Obligatorio.",
        counter30: (value) =>
          value.length >= 15 ||
          value.length === 0 ||
          "El valor debe tener entre 15 y 30 caracteres",
        tamañoarchivo: (value) =>
          !!value &&
          value.size < 5000001 ||
          "Peso Maximo 5MB",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email invalido.";
        },
        sinsimbolos: (value) => {
          const pattern = /^[A-Za-z]+$/;
          return pattern.test(value) || "No se aceptan números ni simbolos";
        },
      },
    };
  },
  computed: {
    cardwidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return "80%";
        case "md":
          return "80%";
        case "lg":
          return "60%";
        case "xl":
          return "60%";
        default:
          return "100%";
      }
    },
  },
  components: {
    Appbar,
  },
  created() {
    this.inicialiarFormlario()

  },
  methods: {
    pruebass(){
      console.log(this.datos.documentoSolicitud.size)
    },
    saveFormulario() {
      this.loadingSend = true
      // alert(this.$conexionGlobalB)
      let formData = new FormData();
      formData.append("funcion", "savePqrsd");
      formData.append("correo", this.datos.correo);
      formData.append("radicado", this.datos.radicado);
      formData.append("codigoPqrd", this.datos.codigoPqrd);
      formData.append("codigoSolicitud", this.datos.codigoSolicitud);
      formData.append(
        "unidadCorrespondencia",
        this.datos.unidadCorrespondencia
      );
      formData.append("fechaRecibido", this.datos.fechaRecibido);
      formData.append("tipoSolicitud", this.datos.tipoSolicitud);
      formData.append("otroTipoSolicitud", this.datos.otroTipoSolicitud);
      formData.append("tipoAsunto", this.datos.tipoAsunto);
      formData.append("asunto", this.datos.asunto);
      formData.append("predial", this.datos.predial);
      formData.append("nombreSolicitante", this.datos.nombreSolicitante);
      formData.append("entidadDependencia", this.datos.entidadDependencia);
      formData.append("correoNotificacion", this.datos.correoNotificacion);
      formData.append("correoNotificacion", this.datos.correoNotificacion);
      formData.append("telefono", this.datos.telefono);
      formData.append("prioridad", this.datos.prioridad);
      if(this.datos.prioridad === 'Jurídica'){
        formData.append("prioridadJuridica", this.datos.prioridadJuridica);
      }else{
        formData.append("prioridadJuridica", "");
      }      
      formData.append("usuario", this.$cookies.get("user"));
      // formData.append("pdf", this.datos.documentoSolicitud);
      formData.append("documentoSolicitud", this.datos.documentoSolicitud);
      // console.log(formData);
      this.deshabilitarGuardar = true;
      axios({
        method: "post",
        url: this.$conexionGlobal + "php/pqrsd.php",
        data: formData,
        config: { headers: { "content-Type": "multipart/form-data" } },
      }).then((respuesta) => {
        //console.log(respuesta)
        this.existeUsuario = 1;
        //this.inicializarregistroUsuarios();

        if (respuesta.data[0].estado === 1) {
          this.$swal.fire({
            position: "warning",
            icon: "success",
            title: respuesta.data[0].msg,
            showConfirmButton: false,
            timer: 3000,
          });
          this.inicialiarFormlario()
           this.$nextTick(() => {
            this.loadingSend = false
           })
        } else {
          this.$swal.fire({
            position: "warning",
            icon: "error",
            title: respuesta.data[0].msg,
            showConfirmButton: false,
            timer: 10000,
          });
          this.$nextTick(() => {
            this.loadingSend = false
           })
        }
      });
    },
    inicialiarFormlario(){
      this.datos = {
        correo: "",
        radicado: "",
        codigoPqrd: "",
        codigoSolicitud: "",
        unidadCorrespondencia: "",
        fechaRecibido: "",
        tipoSolicitud: "",
        otroTipoSolicitud: "",
        tipoAsunto: "",
        asunto: "",
        predial: "",
        nombreSolicitante: "",
        entidadDependencia: "",
        documentoSolicitud: "",
        correoNotificacion: "",
        telefono: "",
        prioridad: "",
        prioridadJuridica: "Tutelas - acciones populares IAS",
        usuario: ""
      }
    }
    // ,
    //   validaUser(){
    //   const obj = { funcion: 'validaUser', usuario: this.user }
    //   axios.post(this.$conexionGlobal + 'php/pqrsd.php', obj)
    //     .then((respuesta) => {
    //       // console.log(respuesta.data[1])
    //       console.log(respuesta.data)
    //       if (respuesta.data[0].estado === 1) {
    //         this.$cookies.set("userPerfil",respuesta.data[1].rol,"16h")
    //         this.$cookies.set("user",this.user.user,"16h")
    //         this.$cookies.set("userName",respuesta.data[1].establecimiento,"16h")

    //          this.$swal.fire({
    //             position: 'warning',
    //             icon: 'success',
    //             title: 'Bienvenid@',
    //             showConfirmButton: false,
    //             timer: 3000
    //           })

    //         if(respuesta.data[1].rol === 'admin'){

    //           // this.$swal.fire({
    //           //   position: 'warning',
    //           //   icon: 'success',
    //           //   title: 'Bienvenid@',
    //           //   showConfirmButton: false,
    //           //   timer: 3000
    //           // })
    //           this.$router.push({
    //             name: 'Solicitudes'
    //           });
    //         }else if(respuesta.data[1].rol === 'validator'){
    //           this.$router.push({
    //             name: 'FormatosReportados'
    //           });
    //         }else{
    //           // this.$swal.fire({
    //           //   position: 'warning',
    //           //   icon: 'success',
    //           //   title: 'Usuario correcto, Pronto estará habilitada esta plataforma',
    //           //   showConfirmButton: false,
    //           // })

    //           // this.$swal.fire({
    //           //   position: 'warning',
    //           //   icon: 'success',
    //           //   title: 'Bienvenid@',
    //           //   showConfirmButton: false,
    //           //   timer: 3000
    //           // })
    //           this.$router.push({
    //             name: 'Pqrsd'
    //           });
    //         }

    //       }else{
    //         // console.log(respuesta)
    //         this.$swal.fire({
    //           title: respuesta.data[0].msg,
    //           showClass: {
    //             popup: 'animate__animated animate__fadeInDown'
    //           },
    //           hideClass: {
    //             popup: 'animate__animated animate__fadeOutUp'
    //           }
    //         })
    //         this.existeUsuario = 1
    //         this.inicializarregistroUsuarios
    //       }
    //     })
    // },
  },
};
</script>