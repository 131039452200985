<template>
  <v-main class="primary" >
  <Appbar />
  <v-card class="ma-5 ">
      <v-card-title>
          <v-icon left>mdi-account-key</v-icon>
          Cambiar de contraseña</v-card-title>
      <v-row class="ma-5">
        <!-- <p style="font-size:11px;"><v-icon color="warning" left>mdi-alert</v-icon>IMPORTANTE: Debe ingresar el NIT de la misma forma en que hizo el registro (Con o Sin Digito de verificación) y el correo electrónico registrado.</p> -->
      </v-row>
      <v-form v-model="formValida">
        <v-row class="ma-5" dense >
            <v-col cols="4">
                <v-text-field
                    v-model="usuario.usuario"
                    label="Usuario"
                    :rules="[rules.required]"
                    disabled
                />
            </v-col>
            <v-col cols="8">
                <v-text-field
                    v-model="usuario.establecimiento"
                    label="Nombre usuario"
                    disabled
                />
            </v-col>
          </v-row>
          <v-row class="ma-5">
            <v-col cols="4">
                 <v-text-field
                  v-model="usuario.passActual"
                  name="name"
                  label="Contraseña Actual"
                  :append-icon="ocultar ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="ocultar ? 'password' : 'text'"
                  @click:append="ocultar = !ocultar"
                  :rules="[rules.required]"
                />
            </v-col>
          </v-row>
          <v-row class="ma-5">
            <v-col cols="4">
                 <v-text-field
                  v-model="usuario.passNuevo1"
                  name="name"
                  label="Nueva Contraseña"
                  :append-icon="ocultar1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="ocultar1 ? 'password' : 'text'"
                  @click:append="ocultar1 = !ocultar1"
                  :rules="[rules.required, , rules.counter]"
                  minlength="5"
                  maxlength="20"
                />
            </v-col>
          </v-row>
          <v-row class="ma-5">
            <v-col cols="4">
                 <v-text-field
                  v-model="usuario.passNuevo2"
                  name="name"
                  label="Confirmar nueva Contraseña"
                  :append-icon="ocultar2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="ocultar2 ? 'password' : 'text'"
                  @click:append="ocultar2 = !ocultar2"
                  :rules="[rules.required, rules.counter]"
                  minlength="5"
                  maxlength="20"
                />
            </v-col >
          </v-row>
          <v-row class="ma-5">
            <v-col >
                <v-btn
                  class="success"
                  :disabled="!formValida"
                  @click="cambiarPassword"
                >
                  <v-icon left>mdi-content-save</v-icon> Guardar
                </v-btn>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
      </v-form>
  </v-card>
  </v-main>
</template>
<script>
import Appbar from '../components/BarSesion'
import axios from 'axios'
export default {
   data() {
      return {
        usuario: {},
        ocultar: true,
        ocultar1: true,
        ocultar2: true,
        formValida: true,
        rules: {
              required: value => !!value || 'Obligatorio.',
              counter: value => value.length >= 5 || 'Min 5 caracteres',
              email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Email invalido.'
            }
        }
      }
      
   },
   created() {
    this.inicializarUsuario()
    this.usuario.usuario = this.$cookies.get("user")
    this.usuario.establecimiento = this.$cookies.get("userName")
    
   },
   methods: {
        inicializarUsuario(){
          this.usuario = {
            passActual: '',
            establecimiento: '',
            passNuevo1: '',
            passNuevo2: '',
            usuario: ''
          }
        },
        cambiarPassword () {
          if(this.usuario.passNuevo1 === this.usuario.passNuevo2){
            const obj = { funcion: 'CambiarPassword', usuario: this.usuario }
            axios.post(this.$conexionGlobal + 'php/users.php', obj)
              .then((respuesta) => {
                
                if (respuesta.data[0].estado === 1) {
                  this.$swal.fire({
                        position: 'warning',
                        icon: 'success',
                        title: respuesta.data[0].msg,
                        showConfirmButton: false,
                        timer: 5000
                      })
                  this.$router.push({
                    name: 'Login'
                  })
                } else {
                  this.$swal.fire({
                        position: 'warning',
                        icon: 'error',
                        title: respuesta.data[0].msg,
                        showConfirmButton: false,
                        timer: 3000
                      })
                }
              })
          }
          else
          {
            this.$swal.fire({
                position: 'warning',
                icon: 'error',
                title:'Error al confirmar la Contraseña Nueva',
                // text: 'Contraseña nueva no coinciden, debe corregir.',
                showConfirmButton: false,
                timer: 5000
              })
          }
        },
  },
  components:{
    Appbar
  }

}
</script>