import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSweetalert2 from 'vue-sweetalert2';
import VueCookies from 'vue-cookies'


Vue.config.productionTip = false
Vue.use(VueCookies)
// Vue.prototype.$conexionGlobal= 'http://localhost/planeacion/backend/'
//  Vue.prototype.$conexionGlobal= 'https://planeacion.pasto.gov.co:9010/planeacion/backend/'

// Conexion  local
Vue.prototype.$conexionGlobal = 'http://192.168.2.165/planeacion/backend/'

// Conexion al servidor
//  Vue.prototype.$conexionGlobal= 'https://planeacion.pasto.gov.co:9010/planeacion/backend/'

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);