<template>
  <v-main class="primary">
    <Appbar />
    <v-card class="ma-5">
      <v-card-title>
        <v-icon left>mdi-account-circle</v-icon>
        USUARIOS | Gestión</v-card-title
      >
      <v-row>
        <v-col>
          <v-data-table
            :headers="headersUsuarios"
            :items="dessertsUsuarios"
            :search="search"
            dense
            item-key="id"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat color="blue-grey lighten-5">
                <v-card-title>
                  <v-icon left color="red darken-1">
                    mdi-format-list-bulleted
                  </v-icon>
                  Listado de usuarios
                </v-card-title>
                <v-divider class="mx-4" inset vertical />
                <v-spacer />
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon>
                      <v-icon
                        class="blue--text"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="abrirNuevo"
                        large
                      >
                        mdi-account-plus
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Nuevo Usuario</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon>
                      <v-icon
                        class="green--text"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="downloadCSVData"
                        large
                      >
                        mdi-file-export
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Exportar a CSV</span>
                </v-tooltip>
                
              </v-toolbar>
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
              <v-tooltip bottom color="info">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    @click="editarUsuario(item)"
                    color="info"
                    v-on="on"
                    v-bind="attrs"
                  >
                    mdi-circle-edit-outline
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.estado`]="{ item }">
              <v-tooltip bottom v-if="item.estado == 'A'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2" color="success" v-on="on" v-bind="attrs">
                    mdi-check-decagram
                  </v-icon>
                </template>
                <span>Activo</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.estado == 'I'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2" color="gray" v-on="on" v-bind="attrs">
                    mdi-check-decagram
                  </v-icon>
                </template>
                <span>Inactivo</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-dialog
              v-model="dialogEdicion"
              v-bind="$attrs"
              max-width="1000"
              eager
              permanent
              v-on="$listeners"
            >
              <v-card>
                <v-form v-model="validaedicion">
                  <v-card-title>Editar usuario</v-card-title>
                  <v-row dense class="ma-3">
                    <v-col cols="2">
                      <v-text-field
                        v-model="edicion.id"
                        label="Id"
                        :rules="[rules.required]"
                        dense
                        outlined
                        readonly
                        class="bg-purple-darken-2 text-center"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="edicion.username"
                        label="Usuario"
                        dense
                        outlined
                        readonly
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="edicion.nombre"
                        label="Nombre / Descripción"
                        :rules="[rules.required]"
                        dense
                        outlined
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="edicion.id_rol"
                        label="Rol"
                        :rules="[rules.required]"
                        :items="itemsRoles"
                        item-value="id"
                        item-text="descripcion"
                        dense
                        outlined
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="edicion.dependencia"
                        label="Codigo Dependencia"
                        :rules="[rules.required]"
                        dense
                        outlined
                        v-if="edicion.id_rol === 'RESPO'"
                        maxlength="20"
                      />
                    </v-col>
                    
                    <v-col cols="3">
                      <v-autocomplete
                        v-model="edicion.estado"
                        label="Estado"
                        :rules="[rules.required]"
                        :items="itemsEstados"
                        item-value="id"
                        item-text="descripcion"
                        dense
                        outlined
                      />
                    </v-col>
                    <v-col cols="4">
                    
                      <v-autocomplete
                        v-model="edicion.actualizarPass"
                        label="Actualizar Contraseña"
                        :items="itemsActualizar"
                        item-value="id"
                        item-text="descripcion"
                        dense
                        outlined
                      />
                    </v-col>
                    <v-col cols="4" >
                        <v-text-field
                          v-if="edicion.actualizarPass === 'S'"
                          v-model="edicion.passNuevo1"
                          name="name"
                          label="Nueva Contraseña"
                          :append-icon="ocultar1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="ocultar1 ? 'password' : 'text'"
                          @click:append="ocultar1 = !ocultar1"
                          :rules="[rules.counter]"
                          minlength="5"
                          maxlength="20"
                          outlined
                          dense
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                          v-if="edicion.actualizarPass === 'S'"
                          v-model="edicion.passNuevo2"
                          name="name"
                          label="Confirmar nueva Contraseña"
                          :append-icon="ocultar2 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="ocultar2 ? 'password' : 'text'"
                          @click:append="ocultar2 = !ocultar2"
                          :rules="[rules.counter]"
                          minlength="5"
                          maxlength="20"
                          outlined
                          dense
                        />
                    </v-col >
                  </v-row>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    
                    <v-btn
                      class="error"
                      @click="cancelarEdicion"
                    >
                      <v-icon left>mdi-cancel</v-icon> Cancelar
                    </v-btn>
                    <v-btn
                      class="success"
                      @click="guardarEdicion"
                      :disabled="!validaedicion"
                      :loading="loadingGuardar"
                    >
                      <v-icon left>mdi-content-save</v-icon> Guardar
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
      </v-dialog>
      <v-dialog
              v-model="dialogNuevo"
              v-bind="$attrs"
              max-width="1000"
              eager
              permanent
              v-on="$listeners"
            >
              <v-card>
                <v-form v-model="validaNuevo">
                  <v-card-title>Nuevo usuario</v-card-title>
                  <v-row dense class="ma-3">
                    <v-col cols="4">
                      <v-text-field
                        v-model="nuevo.username"
                        label="Usuario"
                        dense
                        outlined
                      />
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        v-model="nuevo.nombre"
                        label="Nombre / Descripción"
                        :rules="[rules.required]"
                        dense
                        outlined
                      />
                    </v-col>
                    <v-col cols="8">
                      <v-autocomplete
                        v-model="nuevo.id_rol"
                        label="Rol"
                        :rules="[rules.required]"
                        :items="itemsRoles"
                        item-value="id"
                        item-text="descripcion"
                        dense
                        outlined
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="nuevo.dependencia"
                        label="Codigo Dependencia"
                        :rules="[rules.required]"
                        dense
                        outlined
                        v-if="nuevo.id_rol === 'RESPO'"
                        maxlength="20"
                      />
                    </v-col>
                    
                    <v-col cols="2">
                      <v-autocomplete
                        v-model="nuevo.estado"
                        label="Estado"
                        :rules="[rules.required]"
                        :items="itemsEstados"
                        item-value="id"
                        item-text="descripcion"
                        dense
                        outlined
                      />
                    </v-col>
                    <v-col cols="5" >
                        <v-text-field
                          v-model="nuevo.passNuevo1"
                          name="name"
                          label="Nueva Contraseña"
                          :append-icon="ocultar1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="ocultar1 ? 'password' : 'text'"
                          @click:append="ocultar1 = !ocultar1"
                          :rules="[rules.counter]"
                          minlength="5"
                          maxlength="20"
                          outlined
                          dense
                        />
                    </v-col>
                    <v-col cols="5">
                        <v-text-field
                          v-model="nuevo.passNuevo2"
                          name="name"
                          label="Confirmar nueva Contraseña"
                          :append-icon="ocultar2 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="ocultar2 ? 'password' : 'text'"
                          @click:append="ocultar2 = !ocultar2"
                          :rules="[rules.counter]"
                          minlength="5"
                          maxlength="20"
                          outlined
                          dense
                        />
                    </v-col >
                  </v-row>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    
                    <v-btn
                      class="error"
                      @click="cancelarNuevo"
                    >
                      <v-icon left>mdi-cancel</v-icon> Cancelar
                    </v-btn>
                    <v-btn
                      class="success"
                      @click="guardarNuevo"
                      :loading="loadingGuardar"
                      :disabled="!validaNuevo"
                    >
                      <v-icon left>mdi-content-save</v-icon> Guardar
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
      </v-dialog>
    </v-card>
  </v-main>
</template>
<script>
import Appbar from "../components/BarSesion";
import axios from "axios";

export default {
  data() {
    return {
      loadingGuardar: false,
      ocultar1: true,
      ocultar2: true,
      itemsRoles: [],
      nuevo: {
        dependencia : '',
        estado : '',
        fecharegistro : '', 
        id : '',
        id_rol : '',
        nombre : '',
        username : '',
        passNuevo1 : '',
        passNuevo2 : '',
        actualizarPass : 'N'
      },
      edicion: {
        dependencia : '',
        estado : '',
        fecharegistro : '', 
        id : '',
        id_rol : '',
        nombre : '',
        username : '',
        passNuevo1 : '',
        passNuevo2 : '',
        actualizarPass : 'N'
      },
      itemsActualizar: [
        {id:"S", descripcion:"Si"},
        {id:"N", descripcion:"No"}
      ],
      itemsEstados: [
        {id:"A", descripcion:"Activo"},
        {id:"I", descripcion:"Inactivo"}
      ],
      search: '',
      rules: {
        required: (value) => !!value || "Obligatorio.",
        counter: (value) => !!value && value.length >= 5 || "Min 5 caracteres",
        tamañoarchivo: (value) =>
          !!value &&
          value.size < 5000001 ||
          "Peso Maximo 5MB",
        solotamañoarchivo: (value) =>
          ( !value || !value.size || value.size < 5000001) ||
          "Peso Maximo 5MB",
        reasignacion: (value) => (!!value && value !== this.asignacion.profesionalAnterior) || "No puede ser vacio o igual al actual",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email invalido.";
        },
      },
      validaedicion: false, 
      validaNuevo: false, 
      dialogEdicion: false, 
      dialogNuevo: false, 
      headersUsuarios: [
        {
          text: "Id",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "Usuario", value: "username" },
        { text: "Nombre o Descripción", value: "nombre" },
        { text: "Rol", value: "rolesdesc" },
        { text: "Fecha de registro", value: "fecharegistro" },
        { text: "Estado", value: "estado" },
        { text: "Codigo dependencia", value: "dependencia" },
        { text: "Acciones", value: "acciones" },
      ],
      dessertsUsuarios: [],
    };
  },
  created() {
    
    this.moment = require("moment");
    this.buscarUsuarios();
    // this.validaRolForm()
  },
  methods: {
    guardarNuevo(){
      // console.log(this.nuevo.actualizarPass)
      // this.edicion.actualizarPass = this.edicion.actualizarPass !== "S" ? "N" : this.edicion.actualizarPass
      this.todoOK = false
      // if(this.edicion.actualizarPass){
      if(this.nuevo.passNuevo1 === this.nuevo.passNuevo2){
        this.todoOK = true
      }else{
        this.$swal.fire({
            position: "warning",
            icon: "error",
            title: "Error en la confirmacion de la contraseña",
            showConfirmButton: false,
            timer: 1500,
          });
      }
      // }
      if(this.todoOK){
          this.loadingGuardar= true
          const obj = {
          funcion: "postNewUser",
          datosUsuario: this.nuevo,
          usuario: this.$cookies.get("user")
        };
        axios.post(this.$conexionGlobal + "php/gestionusuarios.php", obj).then((respuesta) => {
          if (respuesta.data[0].estado === 1) {
            this.$swal.fire({
              position: "warning",
              icon: "success",
              title: respuesta.data[0].msg,
              showConfirmButton: false,
              timer: 1500,
            });
            this.$nextTick(() => {
              this.loadingGuardar= false
            this.dialogNuevo = false
            this.buscarUsuarios()
            });
          }else{
            this.$swal.fire({
              position: "warning",
              icon: "error",
              title: respuesta.data[0].msg,
              showConfirmButton: false,
              timer: 3000
            });
            this.$nextTick(() => {
              this.loadingGuardar= false
              this.buscarUsuarios()
            });
          }
        });
      }
    },
    guardarEdicion(){
      console.log(this.edicion.actualizarPass)
      this.edicion.actualizarPass = this.edicion.actualizarPass !== "S" ? "N" : this.edicion.actualizarPass
      this.todoOK = false
      if(this.edicion.actualizarPass){
        if(this.edicion.passNuevo1 === this.edicion.passNuevo2){
          this.todoOK = true
        }else{
          this.$swal.fire({
              position: "warning",
              icon: "error",
              title: "Error en la confirmacion de la nueva contraseña",
              showConfirmButton: false,
              timer: 1500,
            });
        }
      }
      if(!this.edicion.actualizarPass || this.todoOK){
          this.loadingGuardar= true
          const obj = {
          funcion: "putEdicionUser",
          datosUsuario: this.edicion,
          usuario: this.$cookies.get("user")
        };
        axios.post(this.$conexionGlobal + "php/gestionusuarios.php", obj).then((respuesta) => {
          if (respuesta.data[0].estado === 1) {
            this.$swal.fire({
              position: "warning",
              icon: "success",
              title: respuesta.data[0].msg,
              showConfirmButton: false,
              timer: 1500,
            });
            this.$nextTick(() => {
              this.loadingGuardar= false
              this.dialogEdicion = false
              this.buscarUsuarios()
            });
          }else{
            this.$swal.fire({
              position: "warning",
              icon: "error",
              title: respuesta.data[0].msg,
              showConfirmButton: false,
              timer: 3000,
            });
            this.$nextTick(() => {
              this.loadingGuardar= false
              this.buscarUsuarios()
            });
          }
        });
      }
    },
    traeRoles() { 
      this.itemsRoles = []
      if(this.$conexionGlobal){
        this.$nextTick(() => {
          const obj = {
          funcion: "getRoles"
          };
          axios
            .post(this.$conexionGlobal + "php/gestionusuarios.php", obj)
            .then((respuesta) => {
              if (respuesta.data[0].estado === 1) {
                // this.itemsRoles = Object.assign({}, respuesta.data);
                for (let i = 1; i < respuesta.data.length; i++) {
                  this.itemsRoles.push(respuesta.data[i])
                }
              }
            });
      });
      }
    },
    abrirNuevo(){
      this.loadingGuardar = false
      this.traeRoles()
      this.nuevo = {
        dependencia : '',
        estado : 'A',
        fecharegistro : '', 
        id : '',
        id_rol : '',
        nombre : '',
        username : '',
        passNuevo1 : '',
        passNuevo2 : '',
        actualizarPass : 'N'
      }
      this.dialogNuevo = true
    },
    editarUsuario(item){
      this.loadingGuardar = false
      console.log(item)
      
      this.traeRoles()
      this.edicion = {
        dependencia : '',
        estado : '',
        fecharegistro : '', 
        id : '',
        id_rol : '',
        nombre : '',
        username : '',
        passNuevo1 : '',
        passNuevo2 : '',
        actualizarPass : 'N'
      }
      // this.edicion = Object.assign({}, item)
      // this.edicion.actualizarPass = "N"
      this.edicion.dependencia = item.dependencia
      this.edicion.estado = item.estado
      this.edicion.fecharegistro = item.fecharegistro 
      this.edicion.id = item.id
      this.edicion.id_rol = item.id_rol
      this.edicion.nombre = item.nombre
      // this.edicion.passNuevo1 = item.passNuevo1
      // this.edicion.passNuevo2 = item.passNuevo2
      // this.edicion.rolesdesc = item.rolesdesc
      this.edicion.username = item.username
      this.edicion.passNuevo1 = ""
      this.edicion.passNuevo2 = ""
      this.dialogEdicion = true
      console.log(this.edicion)
    },
    cancelarEdicion(){
      this.dialogEdicion = false
    },
    cancelarNuevo(){
      this.dialogNuevo = false
    },
    buscarUsuarios() {
      this.dessertsUsuarios = [];
      const obj = {
        funcion: "getUsuarios",
        filtro: "",
        usuario: this.$cookies.get("user"),
        rol: this.$cookies.get("userPerfil"),
      };
      axios
        .post(this.$conexionGlobal + "php/gestionusuarios.php", obj)
        .then((respuesta) => {
          console.log(respuesta);
          if (respuesta.data[0].estado === 1) {
            for (let i = 1; i < respuesta.data.length; i++) {
              this.dessertsUsuarios.push(respuesta.data[i]);
            }
          }
        });
    },
  },
  components: {
    Appbar,
  },
};
</script>