<template>
  <v-main class="primary">
    <Appbar />
    <v-card class="ma-5 ">
      <v-card-title>
        <v-icon left>mdi-database-search</v-icon>
        PQRSD | Tablero de Control</v-card-title
      >
      <v-row dense class="ma-2">
        <v-col cols="12" md="2">
          <v-text-field
            v-model="parametros.fechaIni"
            label="Fecha inicial"
            :rules="[rules.required]"
            dense
            type="date"
            outlined
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="parametros.fechaFin"
            label="Fecha final"
            :rules="[rules.required]"
            dense
            type="date"
            outlined
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-autocomplete
            v-model="parametros.canalEnvio"
            label="Canal de Envio"
            :rules="[rules.required]"
            :items="itemsCanalEnvio"
            dense
            outlined
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-autocomplete
            v-model="parametros.estado"
            label="Estado"
            :rules="[rules.required]"
            :items="itemsEstado"
              item-value="cod"
              item-text="text"
            dense
            outlined
          />
        </v-col>
        
        <v-spacer></v-spacer>
        <v-col cols="12" md="2">
              <v-btn
                color="success"
                @click="busquedaGeneral"
                width="100%"
              >
                Buscar
              </v-btn>
            </v-col>
      </v-row>
      
      <v-row class="ma-2">
        <v-col>
          <v-data-table
            :headers="headersPqrsd"
            :items="dessertsPqrsd"
            :search="search"
            dense
            item-key="id"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat color="blue-grey lighten-5">
                <v-card-title>
                  <v-icon left color="red darken-1">
                    mdi-format-list-bulleted
                  </v-icon>
                  Trámites de derechos de petición por Funcionario				
                </v-card-title>
                <v-divider class="mx-4" inset vertical />
                <v-spacer />
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon>
                    <v-icon
                    class="green--text"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="downloadCSVData"
                    large
                  >
                    mdi-file-export
                  </v-icon>
                  </v-btn>
                  
                </template>
                <span>Exportar a CSV</span>
              </v-tooltip>
              </v-toolbar>
              
            </template>
            <template slot="body.append">
                <tr class="pink--text">
                    <th class="title">Totales</th>
                    <th class="title" style="text-align:right">{{ sumField('total') }}</th>
                    <th class="title" style="text-align:right">{{ sumField('asignados') }}</th>
                    <th class="title" style="text-align:right">{{ sumField('respondidos') }}</th>
                    <th class="title" style="text-align:right">{{ sumField('pendientes') }}</th>
                    <th class="title" style="text-align:right">{{ sumField('finalizados') }}</th>
                    <th class="title" style="text-align:right">{{ sumField('respuestainmediata') }}</th>
                    <th class="title" style="text-align:right">{{ sumField('vencido') }}</th>
                    <th class="title" style="text-align:right">{{ sumField('vencido10') }}</th>
                    <!-- <th class="title">{{ sumField('vencido30') }}</th> -->
                    <th class="title" style="text-align:right">{{ sumPorcentaje('cumplimiento') }}%</th>

                </tr>
            </template>

            <!-- <template v-slot:[`item.documentosadjuntos`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    @click="descargarPDF(item.documentosolicitud, 'encabezado')"
                    color="warning"
                    v-on="on"
                    v-bind="attrs"
                    v-if="item.documentosolicitud !== null && item.documentosolicitud !== ''"
                  >
                    mdi-eye-circle
                  </v-icon>
                </template>
                <span>Documentos solicitud</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    @click="descargarPDF(item.respuestaadjuntos, 'encabezado')"
                    color="blue"
                    v-on="on"
                    v-bind="attrs"
                    v-if="item.respuestaadjuntos !== null && item.respuestaadjuntos !== '' "
                  >
                    mdi-eye-circle
                  </v-icon>
                </template>
                <span>Documentos proyecto de respuesta</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    @click="descargarPDF(item.respuestafirmadaadjunta, 'encabezado')"
                    color="success"
                    v-on="on"
                    v-bind="attrs"
                    v-if="item.respuestafirmadaadjunta !== null && item.respuestafirmadaadjunta !== '' "
                  >
                    mdi-eye-circle
                  </v-icon>
                </template>
                <span>Documentos respuesta firmada</span>
              </v-tooltip>
            </template> -->

            <!-- <template v-slot:[`item.respuestaurl`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    @click="abrirDoc(item.respuestaurl)"
                    color="blue"
                    v-on="on"
                    v-bind="attrs"
                    v-if="item.respuestaurl !== null && item.respuestaurl !== ''"
                  >
                    mdi-google
                  </v-icon>
                </template>
                <span>Abrir Google Docs</span>
              </v-tooltip>
            </template> -->


            <!-- <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong> Unidad de Correspondencia: </strong> {{ item.unidadcorrespondencia }} <br>
                <strong> Fecha de recibido: </strong>{{ item.fecharecibido }} <br>
                <div v-if="item.tiposolicitud === 'Otro'"><strong> Otro tipo de solicitud: </strong>{{ item.otrotiposolicitud }} <br></div>
                <strong> Solicitante: </strong>{{ item.nombresolicitante }} <br>
                <strong> Entidad/Dependencia: </strong>{{ item.entidaddependencia }} <br>
                <strong> Telefono: </strong>{{ item.telefono }} <br>
                <strong> Nro. Radicado: </strong>{{ item.radicado }} <br>
                <strong> Cod. PQRD: </strong>{{ item.codigopqrd }} <br>
                <strong> Cod. Solicitud: </strong>{{ item.codigosolicitud }} <br>
                <strong> Tipo de asunto: </strong>{{ item.tipoasunto }} <br>
                <strong> Nro. Predial: </strong>{{ item.predial }} <br>
                <strong> Responsable respuesta: </strong>{{ item.respuestaresponsable }} <br>
                <strong> Fecha de envio respuesta: </strong>{{ item.respuestafechaenvio }} <br>
                <strong> Observación respuesta: </strong>{{ item.respuestaobservacion }} <br>
                <strong> Justificación Prorroga: </strong>{{ item.respuestaprorroga }} <br>
              </td>
            </template> -->
          </v-data-table>
        </v-col>
      </v-row>
      <v-row class="ma-2">
        <v-col>
          <v-data-table
            :headers="headersPqrsdDep"
            :items="dessertsPqrsdDep"
            :search="searchDep"
            dense
            item-key="id"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat color="blue-grey lighten-5">
                <v-card-title>
                  <v-icon left color="red darken-1">
                    mdi-format-list-bulleted
                  </v-icon>
                  Trámites de derechos de petición por Dependencia					
				
                </v-card-title>
                <v-divider class="mx-4" inset vertical />
                <v-spacer />
                  <v-text-field
                    v-model="searchDep"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon>
                    <v-icon
                    class="green--text"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="downloadCSVData"
                    large
                  >
                    mdi-file-export
                  </v-icon>
                  </v-btn>
                  
                </template>
                
                <span>Exportar a CSV</span>
              </v-tooltip>
              </v-toolbar>
              
            </template>
            <template slot="body.append">
                <tr class="pink--text">
                    <th class="title">Totales</th>
                    <th class="title" style="text-align:right">{{ sumFieldDep('total') }}</th>
                    <th class="title" style="text-align:right">{{ sumFieldDep('respondidos') }}</th>
                    <th class="title" style="text-align:right">{{ sumFieldDep('finalizados') }}</th>
                    <th class="title" style="text-align:right">{{ sumFieldDep('vencido') }}</th>
                    <th class="title" style="text-align:right">{{ sumFieldDep('vencidossinfirmar10') }}</th>
                    <!-- <th class="title" style="text-align:right">{{ sumFieldDep('vencidossinfirmar30') }}</th> -->

                </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
<!-- <BarChart /> -->
      <!-- <v-footer class="d-flex flex-column">
    <div class="bg-teal d-flex w-100 align-center px-4">
      <strong>Estados PQRSD: I-Ingresado | A-Asignado a Profesional | R-Con Respuesta | F-Finalizado</strong>

      <v-spacer></v-spacer>

      <v-btn
        v-for="icon in icons"
        :key="icon"
        class="mx-4"
        :icon="icon"
        variant="plain"
        size="small"
      ></v-btn>
    </div>

    
  </v-footer> -->
    </v-card>
    <v-dialog
      v-model="dialogPDF"
      v-bind="$attrs"
      max-width="80vw"
      eager
      permanent
      v-on="$listeners"
    >
      <v-card>
        <v-card-text class="pa-0">
          <embed
            :src="$conexionGlobal + rutaPDF"
            type="application/pdf"
            width="100%"
            height="600px"
          />
        </v-card-text>
      </v-card>
    </v-dialog>



    
  </v-main>
</template>
<script>
import Appbar from "../components/BarSesion";
import axios from "axios";
// import BarChart from '../components/BarChart'


export default {
  data() {
    return {
      chartData: {
        labels: [ 'January', 'February', 'March' ],
        datasets: [ { data: [40, 20, 12] } ]
      },
      chartOptions: {
        responsive: true
      },
      expanded: [],
      singleExpand: false,
      rol : '',
      validaRol: false,
      parametros: {
        fechaIni: null,
        fechaFin: null,
        estado: "T",
        canalEnvio: "Todos"
      },
      itemsCanalEnvio: [
        "Todos",
        "Correo electronico",
        "Mensajeria"
      ],
      itemsEstado: [
        { cod: "T", text: "Todos" },
        { cod: "A", text: "A - Asignado a profesional" },
        { cod: "R", text: "R - Con proyecto de respuesta ó asignado a dependencia" },
        { cod: "F", text: "F - Finalizados (Todos)" },
        { cod: "FN", text: "F - Finalizado con proyecto de respuesta" },
        { cod: "FI", text: "F - Finalizado con respuesta inmediata" },
      ],
      moment: null,
      
      itemsComunas: [],
      
      itemsAnnos: ["2021", "2022"],
      razonsocial: '',
      validaRespuesta: true,
      validaDetalle: true,
      validaEstado: true,
      dialogDetalle: false,
      dialogEstado: false, 
      formato:[],
      cargando: false,
      search: '',
      searchDep: '',
      asignacion:{},
      respuestaOficio:{
        respuestaObservacion: '',
        respuestaProrroga: '',
        respuestaFechaEnvio: this.moment,
        respuestaAdjuntos: '',
        respuestaUrl: '',
        respuestaResponsable: '',
        asunto: '',
        id: null,
      },
      dialogPDF: false,
      dialogAsignacion: false,
      dialogRespuesta: false,      
      validaContrasena: false,
      ocultar1: true,
      ocultar2: true,
      password1: "",
      password2: "",
      rutaPDF: "",
      usuario: {},
      headersPqrsd: [
        {
          text: "Profesional",
          align: "start",
          sortable: false,
          value: "profesional",
        },
        { text: "Total", value: "total", align: 'end' },
        { text: "Asignados sin respuesta", value: "asignados", align: 'end' },
        { text: "Con Respuesta", value: "respondidos", align: 'end' },
        { text: "Pendientes por finalizar", value: "pendientes", align: 'end' },
        { text: "Finalizados", value: "finalizados", align: 'end' },
        { text: "Finalizados sin Proyecto de respuesta", value: "respuestainmediata", align: 'end' },        
        { text: "Vencido 10 días", value: "vencido", align: 'end' },
        { text: "Vencido 30 días", value: "vencido10", align: 'end' },
        // { text: "Vencido > 30 dias", value: "vencido30" },
        { text: "Cumplimiento (%)", value: "cumplimiento", align: 'end' }
        // { text: "Acciones", value: "acciones" }        
      ],
      headersPqrsdDep: [
        {
          text: "Dependencia",
          align: "start",
          sortable: false,
          value: "respuestaresponsable",
        },
        { text: "Total", value: "total", align: 'end' },
        { text: "Con Respuesta (Sin Firma)", value: "respondidos", align: 'end' },
        { text: "Finalizados", value: "finalizados", align: 'end' },
        { text: "Vencido de 10 días", value: "vencido", align: 'end' },
        { text: "Vencido de 30 días", value: "vencidossinfirmar10", align: 'end' }
        // { text: "Vencido > 30 dias", value: "vencidossinfirmar30", align: 'end' } 
      ],
      // headersDetalle: [
      //   {
      //     text: "Año",
      //     align: "start",
      //     sortable: false,
      //     value: "ano",
      //   },
      //   { text: "Mes", value: "mes" },
      //   { text: "Aprovechables orgánicos", value: "biodegradable" },
      //   { text: "Aprovechables reciclables", value: "reciclables" },
      //   { text: "No Aprovechables", value: "ordinarios" },
      //   { text: "Biosanitarios", value: "biosanitarios" },
      //   { text: "Anatomopatologicos", value: "anatomopatologicos" },
      //   { text: "cortopunzantes", value: "cortopunzantes" },
      //   { text: "De animales", value: "deanimales" },
      //   { text: "Farmacos", value: "farmacos" },
      //   { text: "Citotóxicos", value: "citotóxicos" },
      //   { text: "Quimicos", value: "quimicos" },
      //   { text: "Corrosivo", value: "corrosivo" },
      //   { text: "Reactivo", value: "reactivo" },
      //   { text: "Explosivo", value: "explosivo" },
      //   { text: "Toxico", value: "toxico" },
      //   { text: "Inflamable", value: "inflamable" },
      //   { text: "Radioactivo", value: "radioactivo" },
      //   { text: "RAEE", value: "raee" },
      //   { text: "Total Mes", value: "totalmes" },
      //   { text: "Soporte PDF", value: "actaincineracion", align: "center" }
      // ],
      // itemsResponsableRespuesta:[
      //   'Asistencial',
      //   'Estratificación',
      //   'Jurídica',
      //   'Ordenamiento',
      //   'Urbanísticas',
      //   'Supervisor Obra'],

      // itemsProfesionales: [],

      dessertsPqrsd: [],
      dessertsPqrsdDep: [],
      // dessertsDetalle: [],
      detalleTotales: [],
      // itemsGeneradores: [
      //   {
      //     codigo: "T",
      //     texto: "Todos",
      //   },
      //   {
      //     codigo: "Pequeño",
      //     texto: "Pequeño generador: menor o igual a 10kg mes y menor a 100kg mes",
      //   },
      //   {
      //     codigo: "Mediano",
      //     texto: "Mediano generador: mayor o igual 100kg mes y menor a 1000kg mes",
      //   },
      //   { codigo: "Gran generador", texto: "Gran generador: mayor igual 1000 kg/mes" },
      // ],
      rules: {
        required: (value) => !!value || "Obligatorio.",
        counter: (value) => value.length >= 8 || "Min 8 caracteres",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email invalido.";
        },
      },
    };
  },
  components: {
    Appbar
    // , BarChart
  },
  beforeMount() {
    if (!this.$cookies.get("user")) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  mounted() {

  },
  created() {
    this.moment = require('moment')
    this.date = new Date(), this.y = this.date.getFullYear(), this.m = this.date.getMonth();
    this.firstDay = new Date(this.y, this.m, 1);
    this.lastDay = new Date(this.y, this.m + 1, 0);

    this.firstDay = this.moment(this.firstDay).format('YYYY-MM-DD');
    this.lastDay = this.moment(this.lastDay).format('YYYY-MM-DD');

    this.parametros.fechaIni =  this.firstDay
    this.parametros.fechaFin =  this.lastDay
    // this.traeProfesionales()
    this.busquedaGeneral()
    // this.buscarPordependencia()
    // this.validaRolForm()

  },
  methods: {
    abrirDoc(url){
      window.open(url, '_blank');
    },
    validaRolForm() {
      this.rol = this.$cookies.get('userPerfil')
      console.log("Rol: "+this.rol )
      if(this.rol === 'PROFE' || this.rol === 'CONSU' || this.rol === 'ESTRA' || this.rol === 'JURID' || this.rol === 'PROFE'){
        this.validaRol = true
      }else{
        this.validaRol = false
      }
      
    },
    // traeProfesionales() { 
    //   console.log(this.$conexionGlobal )
    //   if(this.$conexionGlobal){
    //     this.$nextTick(() => {
    //       console.log(this.$conexionGlobal )
    //       this.dessertsPqrsd = [];
    //       const obj = {
    //       funcion: "getProfesionales"
    //       };
    //       axios
    //         .post(this.$conexionGlobal + "php/gestionpqrds.php", obj)
    //         .then((respuesta) => {
    //           console.log("respuesta Profesionales")
    //           console.log(respuesta)
    //           console.log("FIN respuesta Profesionales")
    //           if (respuesta.data[0].estado === 1) {
    //             for (let i = 1; i < respuesta.data.length; i++) {
    //               this.itemsProfesionales.push(respuesta.data[i])
    //             }
    //           }
    //         });
    //       // console.log(this.itemsProfesionales)
    //   });
    //   }
      
      
      
    // },
    sumPorcentaje(key) {
        return this.dessertsPqrsd.reduce((a, b) => Math.round(parseFloat(a) + (parseFloat(b[key]) || 0)/8), 0)
    },
    sumField(key) {
        // sum data in give key (property)
        return this.dessertsPqrsd.reduce((a, b) => a + (b[key] || 0), 0)
    },
    sumFieldDep(key) {
        // sum data in give key (property)
        return this.dessertsPqrsdDep.reduce((a, b) => a + (b[key] || 0), 0)
    },
    busquedaGeneral() {
      console.log("Conexion: " + this.$conexionGlobal)
      this.dessertsPqrsd = [];
      const obj = {
       funcion: "getDatosFuncionario",
        filtro: 'T',
        fechaFin: this.parametros.fechaFin,
        fechaIni: this.parametros.fechaIni,
        estado: this.parametros.estado,
        canalEnvio: this.parametros.canalEnvio,
        usuario: this.$cookies.get("user"),
        rol: this.$cookies.get("userPerfil")
      };
      axios
        .post(this.$conexionGlobal + "php/tablerocontrol.php", obj)
        .then((respuesta) => {
          if (respuesta.data[0].estado === 1) {
            for (let i = 1; i < respuesta.data.length; i++) {
              this.dessertsPqrsd.push(respuesta.data[i]);
            }
          }
        });
      this.buscarPordependencia();
    },

    buscarPordependencia() {
      
      this.dessertsPqrsdDep = [];
      const obj = {
       funcion: "getDatosDependencia",
        filtro: 'T',
        fechaFin: this.parametros.fechaFin,
        fechaIni: this.parametros.fechaIni,
        estado: this.parametros.estado,
        canalEnvio: this.parametros.canalEnvio,
        usuario: this.$cookies.get("user"),
        rol: this.$cookies.get("userPerfil")
      };
      axios
        .post(this.$conexionGlobal + "php/tablerocontrol.php", obj)
        .then((respuesta) => {
          if (respuesta.data[0].estado === 1) {
            for (let i = 1; i < respuesta.data.length; i++) {
              this.dessertsPqrsdDep.push(respuesta.data[i]);
            }
          }
        });
    },
    
    
    descargarPDF(item, tipo) {
      console.log(item)
      if(tipo === 'encabezado'){
        this.rutaPDF = "php/" + item;
      }else{
        this.rutaPDF = "php/" + item;
      }
      
      console.log(item);
      this.dialogPDF = true;
    },
    // oficioRespuesta(item) {
    //   console.log(item)
    //   this.respuestaOficio.id = item.id
    //   this.respuestaOficio.asunto = item.asunto
    //   // this.asignacion.periodo = item.periodo
    //   // console.log(this.asignacion)
      
    //   this.dialogRespuesta = true
      
    // },
    // asignarProfesional(item) {
    //   console.log(item)
    //   this.asignacion.id = item.id
    //   this.asignacion.profesionalAnterior = item.profesional
    //   this.asignacion.periodo = item.periodo
    //   console.log(this.asignacion)
    //   // this.password2 = "";
    //   this.dialogAsignacion = true
    //   // this.usuario = item;
    // },
    // guardarRespuesta(){
      
    //   let formData = new FormData();
    //   formData.append("funcion", "postRespuesta");
    //   formData.append("id", this.respuestaOficio.id);
    //   formData.append("respuestaObservacion", this.respuestaOficio.respuestaObservacion);
    //   formData.append("respuestaProrroga", this.respuestaOficio.respuestaProrroga);
    //   formData.append("respuestaFechaEnvio", this.respuestaOficio.respuestaFechaEnvio);
    //   formData.append("pdf", this.respuestaOficio.respuestaAdjuntos);
    //   formData.append("respuestaUrl", this.respuestaOficio.respuestaUrl);
    //   formData.append("respuestaResponsable", this.respuestaOficio.respuestaResponsable);
    //   formData.append("asunto", this.respuestaOficio.asunto);
    //   formData.append("usuario", this.$cookies.get("user"));
    //   formData.append("estado", 'R');
      
    //   // console.log(formData);
    //   this.deshabilitarGuardar = true;
      
    //   axios({
    //     method: "post",
    //     url: this.$conexionGlobal + "php/gestionpqrds.php",
    //     data: formData,
    //     config: { headers: { "content-Type": "multipart/form-data" } },
    //   }).then((respuesta) => {
    //     //console.log(respuesta)
    //     this.existeUsuario = 1;
    //     //this.inicializarregistroUsuarios();

    //     if (respuesta.data[0].estado === 1) {
    //       this.$swal.fire({
    //         position: "warning",
    //         icon: "success",
    //         title: respuesta.data[0].msg,
    //         showConfirmButton: false,
    //         timer: 3000,
    //       });
    //       this.buscarPqrsd()
    //       this.dialogRespuesta = false
    //     } else {
    //       this.$swal.fire({
    //         position: "warning",
    //         icon: "error",
    //         title: respuesta.data[0].msg,
    //         showConfirmButton: false,
    //         timer: 10000,
    //       });
    //       this.buscarPqrsd()
    //     }
    //   });
      
      // const obj = {
      //   // funcion: "postSolicitudes",
      //   funcion: "postRespuesta",
      //   pqrsd: this.respuestaOficio,
      //   estado: 'R',
      //   usuario: this.$cookies.get("user")
      // };
      // axios.post(this.$conexionGlobal + "php/gestionpqrds.php", obj).then((respuesta) => {
      //   if (respuesta.data[0].estado === 1) {
      //     this.$swal.fire({
      //       position: "warning",
      //       icon: "success",
      //       title: respuesta.data[0].msg,
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //     this.dialogRespuesta = false
      //     this.buscarPqrsd()
      //   }
      // });
    // },
    // guardarPqrsd(){
    //     const obj = {
    //     funcion: "putReAsignacion",
    //     pqrsd: this.asignacion,
    //     estado: 'A',
    //     usuario: this.$cookies.get("user")
    //   };
    //   axios.post(this.$conexionGlobal + "php/gestionpqrds.php", obj).then((respuesta) => {
    //     if (respuesta.data[0].estado === 1) {
    //       this.$swal.fire({
    //         position: "warning",
    //         icon: "success",
    //         title: respuesta.data[0].msg,
    //         showConfirmButton: false,
    //         timer: 1500,
    //       });
    //       this.dialogAsignacion = false
    //       this.buscarPqrsd()
    //     }
    //   });
    // },
    // confirmarActualizacion(){
    //   this.cargando = true
    //   const obj = {
    //     funcion: "actualizaEstadoFormato",
    //     usuario: this.$cookies.get("user"),
    //     formato: this.formato        
    //   };
    //   axios.post(this.$conexionGlobal + "php/gestionpqrds.php", obj).then((respuesta) => {
    //     console.log(respuesta)
    //     if (respuesta.data[0].estado === 1) {
    //       this.$swal.fire({
    //         position: "top-end",
    //         icon: "success",
    //         //title:"Formato actualizado, Nuevo estado: " + this.formato.nuevoEstado,
    //         title:respuesta.data[0].msg,
    //         showConfirmButton: false,
    //         timer: 3000,
    //       });
    //       // this.cargarUsuarios();
    //       //this.dessertsPqrsd
    //       this.inicializarFormato()
    //       this.buscarPqrsd()
    //       this.dialogEstado = false
    //     } 
    //     else {
    //       this.$swal.fire({
    //         position: "warning",
    //         icon: "error",
    //         title: respuesta.data[0].msg,
    //         showConfirmButton: false,
    //       });
    //     }
    //     this.cargando = false
    //   });
    // },
    // cerrarDialogoEstado(){
    //   this.dialogEstado = false
    // },
    // validaFecha(){
    //   if((this.parametros.fechaIni && this.parametros.fechaIni != '') || this.parametros.fechaFin && this.parametros.fechaFin !== ''){
    //     if(this.parametros.fechaIni > this.parametros.fechaFin){
    //       this.parametros.fechaFin = ''
    //     }
    //     this.buscarPqrsd()
    //   }      
    // },
    // actualizarFormato(item, estado){
    //   this.inicializarFormato()
    //   this.formato.id = item.id
    //   this.formato.estadoActual = item.estado
    //   this.formato.nuevoEstado = estado
    //   this.formato.email = item.email
    //   this.formato.nit = item.nit
    //   this.formato.representante = item.representante
    //   this.formato.razonsocial = item.razonsocial      
    //   this.formato.direccion = item.direccion
    //   this.dialogEstado = true
    // },
    inicializarFormato() {
      this.formato = {
        id: null,
        estadoActual: '',
        nuevoEstado: '',
        observacion: '',
        email: '',
        nit: '',
        representante: '',
        razonsocial: '',
        direccion: ''
      };
    },
    // inicializarDetalleTotales() {
    //   this.detalleTotales = {
    //     biodegradable : 0,
    //     reciclables : 0,
    //     ordinarios : 0,
    //     biosanitarios : 0,
    //     anatomopatologicos : 0,
    //     cortopunzantes : 0,
    //     deanimales : 0,
    //     farmacos : 0,
    //     citotóxicos : 0,
    //     quimicos : 0,
    //     corrosivo : 0,
    //     reactivo : 0,
    //     explosivo : 0,
    //     toxico : 0,
    //     inflamable : 0,
    //     radioactivo : 0,
    //     raee : 0,
    //     actaincineracion : 0,
    //     totalmes : 0
    //   };
    // },
    downloadCSVData() {
      let csv = "";
      this.headersPqrsd.forEach((titulo) => {
        csv += titulo.value + ";";
      });
      csv += "\n";
      console.log(this.dessertsResultados);
      this.dessertsPqrsd.forEach((row) => {
        this.headersPqrsd.forEach((titulo) => {
          let aux = row[titulo.value] !== '' && row[titulo.value] !== null && row[titulo.value] !== "undefined" && row[titulo.value] ? row[titulo.value] : "0";
          // csv += row[titulo.value] + ";";
          csv += aux + ";";
        });
        csv += "\n";
      });

      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = "export.csv";
      anchor.click();
    },
  },
};
</script>
<style scoped>
* {
  font-family: "Muli", "roboto", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
</style>
