import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Pqrsd from '../views/pqrsd.vue'
import GestionPqrsd from '../views/GestionPqrsd.vue'
import ConsultaPqrsd from '../views/ConsultaPqrsd.vue'
import TableroControl from '../views/TableroControl.vue'
import CambiarContrasena from '../views/CambiarContrasena.vue'
import GestionUsuarios from '../views/GestionUsuarios.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'catchAll',
    component: Login
  }, 
  {
    path: '/pqrsd',
    name: 'Pqrsd',
    component: Pqrsd
  },
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/planeacion/web/cambiarcontrasena',
    name: 'CambiarContrasena',
    component: CambiarContrasena
  },
  {
    path: '/gestionpqrsd',
    name: 'GestionPqrsd',
    component: GestionPqrsd
  },
  {
    path: '/planeacion/web/gestionusuarios',
    name: 'GestionUsuarios',
    component: GestionUsuarios
  },
  {
    path: '/planeacion/web/consultapqrsd',
    name: 'ConsultaPqrsd',
    component: ConsultaPqrsd
  },
  {
    path: '/planeacion/web/tablerocontrol',
    name: 'TableroControl',
    component: TableroControl
  },
  
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
