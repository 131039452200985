import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify);

export default new Vuetify({
    customVariables: ['~/style/main.scss'],
    lang: {
      locales: { es },
      current: 'es'
    },
    theme: {
        themes: {
          light: {
            primary: '#0c2e50',
            secondary: '#b0bec5',
            accent: '#8c9eff',
            error: '#b71c1c',
            success: '#2E7D32',
            info: '#00838F'
          },
        },
      },
});
