<template>
  <v-main class="primary">
    <!-- <v-app-bar clipped-left fixed app dense>
      <v-img max-height="100%" max-width="200px" contain :src="require('@/assets/logo2.png')" />
      <v-spacer />
      <v-toolbar-items>
      
      </v-toolbar-items>
    </v-app-bar> -->
    <v-card :width="cardwidth" class="fill-height mx-auto">
      <v-form v-model="formulario" class="pt-5">
        <v-img
            contain
            :aspect-ratio="30/9"
            class="mx-auto my-3"
            :src="require('@/assets/pqrs.jpg')"
            
          />
        <center>
          <h2 class="primary white--text" dark>
            SECRETARIA PLANEACIÓN MUNICIPAL | PQRSD
          </h2>
          <strong> Módulo de Gestión</strong>
        </center>
        <v-divider />
        <v-row class="ma-3" dense>
          <v-col cols="12">
            <v-text-field
              v-model="user.user"
              label="Usuario"
              :rules="[rules.required]"
              outlined
              dense
              hide-details
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="user.password"
              name="name"
              label="Contraseña"
              :append-icon="ocultar ? 'mdi-eye' : 'mdi-eye-off'"
              :type="ocultar ? 'password' : 'text'"
              @click:append="ocultar = !ocultar"
              :rules="[rules.required]"
              outlined
              dense
              hide-details
            />
          </v-col>
        </v-row>

        <v-card-actions>
          <v-row class="ma-1" dense>
            <!-- <v-col cols="12" md="6">
              <v-btn  color="info" type="submit" to="/rh1/web/solicitaracceso" width="100%">
                Solicitar acceso
              </v-btn>
            </v-col> -->
            <v-col cols="12" align="end">
              <v-btn
                :disabled="!formulario"
                color="success"
                @click="validaUser"
                width="100%"
              >
                Ingresar
              </v-btn>
            </v-col>
           
          </v-row>
        </v-card-actions>
        
      </v-form>
    </v-card>
    <v-dialog v-model="dialogAyuda" width="800">
      <v-card>
        
        <v-toolbar class="info" dark
          ><v-icon left>mdi-help-box</v-icon>
          Ayuda
        </v-toolbar>
        <v-card-text
          ><br />
          <center>
            <h4>
              DILIGENCIAMIENTO DEL FORMULARIO RH1 - RESIDUOS GENERADOS EN LA
              ATENCION EN SALUD Y OTRAS ACTIVIDADES
            </h4>
          </center>
          <p class="pa-2" style="text-align: justify">
            El Formulario RH1 es el mecanismo mediante el cual los generadores
            de la atención en salud y otras actividades consignan la información
            correspondiente a las cantidades en peso (Kg) y el manejo que
            realiza a cada uno de los residuos generados.
            <br />
            Esta información debe ser entregada a la Secretaría de Salud de
            municipio de Pasto cada seis meses, de lo contrario se aplicará
            medida sanitaria de acuerdo a la normatividad aplicable.
          </p>
          <p class="pa-2" style="text-align: justify">
            Los periodos a reportar deben ser de la siguiente manera: <br />
            Primer semestre (Enero - Junio) Del 01 al 31 de julio de cada
            año.<br />
            Segundo semestre (Julio - Diciembre) Del 02 de enero al 31 de enero
            de cada año
          </p>
          <p class="pa-2" style="text-align: justify; color: red">
            DEBE ADJUNTAR LA SIGUIENTE DOCUMENTACIÓN EN FORMATO PDF:
          </p>
          <p class="pa-2" style="text-align: justify">
            1. Actas individuales por mes del tratamiento de residuos,
            correspondiente al periodo: Enero - Junio, emitidas por la empresa
            gestora de residuos (SALVI LTDA, EMAS PASTO BY VEOLIA, otras).<br />
            2. Contrato con la empresa gestora de residuos (SALVI LTDA, EMAS
            PASTO BY VEOLIA, otras).
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-main>
</template>
<script>
import axios from "axios";
// import myVideo from "vue-video";

export default {
  data() {
    return {
      dialogAyuda: false,
      formulario: true,
      user: {},
      ocultar: true,
      // dialogVideo: false,
      // rutaVideo: "assets/instructivo.mp4",
      // video: {
      //   sources: [
      //     {
      //       //src: this.$conexionGlobal + this.rutaVideo,
      //       src: 'http://localhost/rh1/backend/assets/instructivo.mp4',
      //       // src: "http://204.199.120.6:8011/rh1/backend/assets/instructivo.mp4",
      //       //src: 'http://192.168.20.3:8011/rh1/backend/assets/instructivo.mp4',
      //       type: "video/mp4",
      //     },
      //   ],
      //   options: {
      //     autoplay: false,
      //     volume: 0.6,
      //     poster: "http://covteam.u.qiniudn.com/poster.png",
      //   },
      // },
      rules: {
        required: (value) => !!value || "* Campo Obligatorio.",
      },
    };
  },
  computed: {
    cardwidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return "80%";
        case "md":
          return "50%";
        case "lg":
          return "33%";
        case "xl":
          return "33%";
        default:
          return "100%";
      }
    },
  },
  // components: {
  //   myVideo,
  // },
  methods: {
    validaUser() {
      console.log("$conexionGlobal: "+this.$conexionGlobal)
      const obj = { funcion: "validaUser", usuario: this.user };
      axios.post(this.$conexionGlobal + "php/users.php", obj).then((respuesta) => {
        // console.log(respuesta.data[1])
        console.log(respuesta.data);
        if (respuesta.data[0].estado === 1) {
          this.$cookies.set("userPerfil", respuesta.data[1].id_rol, "16h");
          this.$cookies.set("userName", respuesta.data[1].nombre, "16h");
          this.$cookies.set("user", this.user.user, "16h");
          
          this.$swal.fire({
            position: "warning",
            icon: "success",
            title: "Bienvenid@",
            showConfirmButton: false,
            timer: 3000,
          });

          if (respuesta.data[1].id_rol === "ADMIN") {
            // this.$swal.fire({
            //   position: 'warning',
            //   icon: 'success',
            //   title: 'Bienvenid@',
            //   showConfirmButton: false,
            //   timer: 3000
            // })
            this.$router.push({
              name: "Pqrsd",
            });
          } else if (respuesta.data[1].id_rol === "ASIST") {
            this.$router.push({
              name: "Pqrsd",
            });
          }
          else if (respuesta.data[1].id_rol === "PROFE") {
            this.$router.push({
              name: "GestionPqrsd",
            });
          } else {
            // this.$swal.fire({
            //   position: 'warning',
            //   icon: 'success',
            //   title: 'Usuario correcto, Pronto estará habilitada esta plataforma',
            //   showConfirmButton: false,
            // })

            // this.$swal.fire({
            //   position: 'warning',
            //   icon: 'success',
            //   title: 'Bienvenid@',
            //   showConfirmButton: false,
            //   timer: 3000
            // })
            this.$router.push({
              name: "ConsultaPqrsd",
            });
          }
        } else {
          // console.log(respuesta)
          this.$swal.fire({
            title: respuesta.data[0].msg,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          });
          this.existeUsuario = 1;
          this.inicializarregistroUsuarios;
        }
      });
    },
  },
};
</script>